/************************************************************
*  パンくずリスト のスタイルを定義
*************************************************************/
@use '../../global' as *;

.c-breadcrumbs {
    // padding: 0 clamp(20px, 5vw, 50px);
    min-height: 1.3em;
    overflow: hidden;
    box-sizing: content-box;
    position: relative;
    // bottom: 15px;
    // margin-top: -25px;
    @include mq(sp){
        position: unset;
        margin-top: 0;
    }

    &__inner{
        overflow: hidden;
        margin: 0 auto -3em;
    }

    &__list {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;

        li {
            position: relative;
            display: block;
            margin-right: 0.8em;
            padding-right: 0.8em;
            font-size: 14px;
            white-space: nowrap;
            font-weight: 500;
            color: $bk6;
            // text-shadow: 0 0 clamp(2px,.5vw,4px) $wh1, 0 0 clamp(2px,.5vw,4px) $wh1, 0 0 clamp(2px,.5vw,4px) $wh1, 0 0 clamp(2px,.5vw,4px) $wh1, 0 0 clamp(2px,.5vw,4px) $wh1, 0 0 clamp(2px,.5vw,4px) $wh1, 0 0 clamp(2px,.5vw,4px) $wh1, 0 0 clamp(2px,.5vw,4px) $wh1, 0 0 clamp(2px,.5vw,4px) $wh1, 0 0 clamp(2px,.5vw,4px) $wh1, 0 0 clamp(2px,.5vw,4px) $wh1, 0 0 clamp(2px,.5vw,4px) $wh1, 0 0 clamp(2px,.5vw,4px) $wh1, 0 0 clamp(2px,.5vw,4px) $wh1, 0 0 clamp(2px,.5vw,4px) $wh1, 0 0 clamp(2px,.5vw,4px) $wh1;
            @include mq(sp){
                font-size: 12px;
            }

            a {
                cursor: pointer;
                transition: .3s ease;
                text-decoration: none;
                &:hover {
                    opacity: .8;
                    text-decoration: underline;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 7px;
                    right: -2px;
                    width: 6px;
                    height: 6px;
                    border-top: 1px solid $bk6;
                    border-right: 1px solid $bk6;
                    transform: rotate(45deg);
                    @include mq(sp){
                        top: 6px;
                    }
                }
            }
        }
    }
}