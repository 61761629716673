/************************************************************
*  footerのスタイルを定義
*************************************************************/
@use '../../global' as *;

.s-footer {
    &__bg {
        background: $pi1;
        padding: 30px 0;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 15px;
        // @include mq(sp) {
        //     justify-content: left;
        // }
        &-item {
            margin: 0 clamp(10px,2vw,20px) 5px 0;
            &:last-child {
                margin-right: 0;
            }
            &:nth-child(3n) {
                @include mq(sp) {
                    margin-right: 0;
                }
            }
        }
        // item内のリンク
        &-link {
            color: $wh1;
            font-size: clamp(13px,3.5vw,16px);

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.s-copywriter{
    color:$wh1;
    font-size: clamp(10px,2.5vw,13px);
    text-align:center;
}