/************************************************************
*  baseのスタイルを定義。HTMLタグに対してスタイルをかける
*************************************************************/
@use '../global' as *;

html {
  font-size: 62.5%;
}
.l-body {
  font-size: 1.6rem;
  line-height: 1.5;
  font-family:$ff-yumincho;
  font-weight:500;
  color: $bk1;
  position: relative;
  z-index: 9;
  animation: fadeIn 0.25s ease 0s 1 normal;
  font-feature-settings: "palt";
  letter-spacing: 1.5px;
}
/*コラムシングルのみ目次にstickyをかけているため外しています*/
body.fixed{
  position: fixed;
  width: 100%;
}
img {
  width: 100%;
  height: auto;
  position: relative;
}
div {
  position: relative;
}

summary{
  cursor:pointer;
}
table{
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
}
video{
  width: 100%;
}
