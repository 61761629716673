// /************************************************************
// *  共通ボタン のスタイルを定義
// *************************************************************/
@use '../../global' as *;

[class*="c-btn"]  {
    cursor: pointer;
}

// 矢印

.c-btn__arrow {
    display: inline-block;
    vertical-align: middle;
    // color: $wh1;
    line-height: 1;
    position: absolute;
    width: 0.6em;
    height: 0.6em;
    transform: translateX(-25%) rotate(45deg);
    right: 20px;
    top: calc(50% - 6px);
    transition: all .35s;
    z-index: 4;

    @include mq(sp) {
        top: calc(50% - 6px);
    }

    &::before,
    &::after {
        content: '';
        background: currentColor;
        position: absolute;
        border-radius: .13em;
    }

    &::before {
        top: 0;
        left: 0;
        right: 0;
        height: .13em;
    }

    &::after {
        top: 0;
        right: 0;
        bottom: 0;
        width: .13em;
    }
}

// ズームアイコン

.c-btn__zoom {
    position: relative;

    &::before {
        content: '';
        background: url(../images/common/zoom-icon.jpg) no-repeat;
        background-size: 100%;
        bottom: 0;
        height: clamp(30px, 5.5vw, 55px);
        position: absolute;
        right: 0;
        width: clamp(30px, 5.5vw, 55px);
        z-index: 1;
    }
}

.c-btn01 {
    max-width: 100%;
    width: 400px;
    // margin: 0 auto;
    text-align: center;

    @include arrowBtn;

    &__link {
        color: $wh1;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 27px 30px 27px 28px;
        position: relative;
        transition: all .35s;
        overflow: hidden;
        border: 1px solid transparent;
        background: $pi1;
        min-height: clamp(50px, 7vw, 70px);

        @include mq(sp) {
            padding: 16px 30px 16px 16px;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: -100%;
            top: 0;
            background: $wh1;
            width: 100%;
            height: 100%;
            transition: all .5s;
        }

        &:hover {
            color: $bk1;
            border: 1px solid $bk5;

            &::after {
                left: 0;
            }
        }

        &-txt,
        p {
            position: relative;
            font-size: 16px;
            font-weight: 500;
            width: 100%;
            z-index: 1;
        }
    }


    // フッター ダイエットプログラム
    &--program {
        width: 840px;
        margin: 0 auto;

        .c-btn01__link {
            background: rgba($br14, 70%);
            min-height: clamp(60px, 12.5vw, 125px);
            border-color: $br1;

            &::before {
                right: 215px;
                top: calc(50% - 10px);
            }

            &::after {
                background: rgba($wh1, 70%);
            }

            p {
                display: inline-block;
                width: auto;
                font-size: clamp(16px, 3.5vw, 25px);
                padding: 0 clamp(30px, 5vw, 50px) 0 clamp(20px, 5vw, 50px);
            }

            &:hover {
                p {
                    color: $br14;
                }
            }
        }

        .c-btn__arrow {
            width: 0.5em;
            height: 0.5em;
            top: calc(50% - 8px);
            right: 5px;

            @include mq(sp) {
                top: calc(50% - 5px);
            }

            &::before {
                height: 0.1em;
            }

            &::after {
                width: 0.1em;
            }
        }

        &:hover {
            .c-btn__arrow {
                right: 0px;
            }
        }
    }

    // フッター ダイエットプログラム
    &--brown {
        .c-btn01__link {
            background: linear-gradient(135deg, $br5, $br1);
            border: 1px solid transparent;
        }
    }
}

// 電話ボタン
.c-btn02 {
    background: $br1;
    // width: 450px;
    max-width: 100%;
    position: relative;
    overflow: hidden;

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        color: $wh1;
        line-height: $line-height-13;
        position: relative;
        z-index: 1;
        padding: 18px 10px;
        transition: all .3s;
        min-height: clamp(60px, 10vw, 100px);

        @include mq(sp) {
            padding: 10px;
        }

        &:hover {
            background: lighten($br1, 15%);
        }

        &-txt {
            display: flex;
            align-items: center;

            >img {
                width: 42px;
                margin-right: 10px;
            }

            >p {
                line-height: 1.3;
                font-size: clamp(24px, 3.5vw, 34px);
            }
        }

        &-small {
            font-size: clamp(13px, 3.5vw, 16px);

            @include mq(tab) {
                margin-top: 5px;
            }
        }
    }
    &--w400 {
        width: 400px;
    }
}

// コラム戻るボタン
.c-btn03 {
    &__link {
        // 戻るボタン
        font-size: clamp(13px, 3.5vw, 15px);
        font-weight: 600;
        padding-left: 35px;
        position: relative;
        display: inline-block;

        @include mq(sp) {
            padding-left: 22px;
        }

        &:hover {
            &::before {
                left: 0;
            }
        }

        &::before {
            content: '';
            left: 3px;
            transform: rotate(180deg);
            background: url(../images/column/arrow_btn02.png) no-repeat 0 0;
            background-size: 100% auto;
            display: block;
            height: 22px;
            position: absolute;
            right: 0;
            top: calc(50% - 12px);
            transition: all .35s;
            width: 22px;

            @include mq(sp) {
                width: 15px;
                height: 15px;
                top: calc(50% - 8px);
            }
        }
    }
}

// 詳細を見るボタン
.c-btn04 {
    max-width: 100%;
    width: 400px;
    // margin: 0 auto;
    text-align: center;

    &__link {
        color: $wh1;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: clamp(10px,2vw,20px);
        background: $pi1;
        min-height: clamp(50px, 7vw, 70px);
        border: 1px solid $pi1;
        border-radius: 200px;
        overflow: hidden;
        transition: all .35s;

        &:hover {
            background: $wh1;
            color: $bk1;
            border: 1px solid $bk1;
        }
    }
}