/************************************************************
*  共通ブロック のスタイルを定義 2箇所以上使っているブロックを共通化
*************************************************************/
@use '../../global' as *;

.c-block01 {
    & + & {
        margin-top: clamp(40px,8vw,80px);
    }
}

// // 来院されるお客様のよくあるお悩み(BSクリニックとは)
// // 全身完全医療ダイエット(医療痩身ボディメイクプログラム)
// .c-block02 {
//     &__cont{
//         & + &{
//             margin-top: clamp(30px, 10vw, 100px);
//         }
//         &--mb240{
//             margin-bottom: clamp(50px,24vw,240px);
//             @include mq(sp){
//                 margin-bottom: 30px;
//             }
//         }
//     }
//     &__flex {
//         display: flex;
//         justify-content: space-between;
//         @include mq(sp){
//             display: block;
//         }

//         &+& {
//             margin-top: clamp(30px,10vw,100px);
//         }

//         &-txt {
//             // width: 520px;
//             width: 47.2%;
//             @include mq(tab){
//                 width: 50%;
//             }

//             @include mq(sp){
//                 width: 100%;
//                 margin-bottom: 30px;
//             }
//         }

//         &-img {
//             // width: 550px;
//             width: 50%;
//             @include mq(tab){
//                 width: 45%;
//             }
//             @include mq(sp){
//                 width: 100%;
//             }
//         }

//         &--reverse{
//             flex-direction: row-reverse;
//         }
//     }
// }

// // 施術等
// .c-block03 {
//     &__inner{}

//     &__cont{
//         background: $bk17;

//         & + & {
//             margin-top: clamp(30px,6vw,60px);
//         }

//         &-img{
//             position: relative;
//             &::before{
//                 content: '';
//                 background: rgba($bk1,50%);
//                 width: 100%;
//                 height: 100%;
//                 position: absolute;
//                 top: 50%;
//                 left: 50%;
//                 transform: translateY(-50%) translateX(-50%);
//                 z-index: 1;
//             }
//         }
//         &-ttl{
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             transform: translateY(-50%) translateX(-50%);
//             z-index: 1;
//             font-size: clamp(20px, 4vw, 36px);
//             width: 100%;
//             text-align: center;
//             padding: 0 5px;
//         }
//     }
//     // cont内のbox
//     &__box{
//         padding: clamp(20px,5vw,50px) clamp(20px,4vw,35px) clamp(30px,8vw,80px);
//         border: 1px solid $bk18;
//         border-top: none;

//         &-txt{
//             padding-bottom: clamp(20px,5vw,50px)
//         }
//     }

//     // ダイエット薬で使用(diet_medicine)
//     &__list{
//         display: flex;
//         flex-wrap: wrap;
    
//         @include mq(sp) {
//             justify-content: center;
//         }
    
//         &-item {
//             width: 100%;
//             display: flex;
//             flex-direction: column;
//             background: $bk17;
//             border: 1px solid $bk18;
    
//             &:last-child {
//                 margin-right: 0;
//             }

//             >a{
//                 display: flex;
//                 flex-direction: column;
//                 flex-grow: 1;
//             }
//         }

//         a:hover {
//             img {
//                 transform: scale(1.1);
//             }
//         }
    
//         a{
//             img {
//                 transition: all .3s;
//             }
//         }
//         &--col3{
//             >li{
//                 width: calc(calc(100% - 20px)/3);
//                 margin: 0 10px clamp(30px,6vw,60px) 0;
    
//                 @include mq(sp) {
//                     // width: calc(calc(100% - 10px)/2);
//                     // margin: 0 10px 10px 0;
//                     width: 100%;
//                     margin: 0;
//                 }

//                 // 初期のみ表示
//                 & + li{
//                     @include mq(sp) {
//                         margin-top: 30px;
//                     }
//                 }

//                 &:nth-child(3n) {
//                     margin-right: 0;
//                     @include mq(sp) {
//                         // margin-right: 10px;
//                     }
//                 }
    
//                 &:nth-child(2n) {
//                     @include mq(sp) {
//                         margin-right: 0;
//                     }
//                 }
                
//                 &:last-child {
//                     @include mq(sp) {
//                         margin-right: 0;
//                     }
//                 }
//             }
//         }

//         // list内のimg
//         &-img{
//             position: relative;
//             overflow: hidden;
//             text-align: center;

//             // &::before {
//             //     content: '';
//             //     position: absolute;
//             //     top: 0;
//             //     left: 0;
//             //     width: 100%;
//             //     height: 100%;
//             //     background: rgba($bk1, 50%);
//             //     z-index: 1;
//             // }
//         }

//         // list内のbox
//         &-box{
//             padding: 0 clamp(10px,2vw,20px) clamp(30px,6vw,60px);
//             // flex-grow: 1;
//         }

//         &-ttl{
//             font-size: clamp(18px, 3.5vw, 30px);
//             font-weight: 600;
//             margin-bottom: clamp(10px,2vw,20px);
//             padding: clamp(10px,4vw,40px) clamp(10px,2vw,20px) 0;
//             // flex-grow: 1;
//         }
//     }
// }

// // 会社概要
// $block04_ttl: 200px;
// .c-block04 {
//     &__flex{
//         display: flex;
//         border-bottom: 1px solid $br7;
//         &:last-child{
//             border-bottom: none;
//         }
//         @include mq(sp){
//             display: block;
//             border: none;
//         }

//         &-ttl,
//         &-txt {
//             font-size: clamp(14px,3.5vw,24px);
//             min-height: clamp(50px,12vw,120px);
//             max-width: 100%;
//             display: flex;
//             align-items: center;
//             @include mq(sp) {
//                 min-height: auto;
//                 display: block;
//                 width: 100%;
//                 padding: 5px 10px;
//             }
//         }

//         &-ttl{
//             background: $br1;
//             width: $block04_ttl;
//             justify-content: center;
//             padding: 5px clamp(20px,3vw,30px);
//             // padding-bottom: clamp(10px,2vw,20px);
//         }
//         &-txt {
//             width: calc(100% - $block04_ttl);
//             background: $wh1;
//             color: $bk1;
//             padding: 5px clamp(20px,6vw,60px);
//         }
//     }
// }



// /************************************************************
// *  共通テキスト のスタイルを定義
// *************************************************************/

[class*="c-txt"] {
    font-size: clamp(13px, 3.5vw, 15px);
    letter-spacing: 1px;
}

.c-txt01 {
    p {
        line-height: $line-height-20;
    }

    p+p {
        margin-top: clamp(20px, 5vw, 50px);
    }
}

// /************************************************************
// *  共通余白 のスタイルを定義
// *************************************************************/

// 下層共通余白

.c-padding01 {
    padding-top: clamp(30px,7vw,70px);
    padding-bottom: clamp(50px,20vw,200px);
}

.c-padding02 {
    padding-top: 30px;
    padding-bottom: clamp(50px,20vw,200px);
}

.c-margin01 {
    margin-top: clamp(30px,7vw,70px);
}



// /************************************************************
// *  共通リスト のスタイルを定義
// *************************************************************/

// // 背景を薄い黒色にする
// .c-gray__list {
//     display: flex;
//     flex-wrap: wrap;

//     @include mq(sp) {
//         justify-content: center;
//     }

//     &-item {
//         position: relative;
//         overflow: hidden;
//         height: 100%;
//         width: 100%;
//         display: block;

//         &:last-child {
//             margin-right: 0;
//         }

//         &::before {
//             content: '';
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//             background: rgba($bk1, 50%);
//             z-index: 1;
//         }

//         p {
//             font-size: clamp(14px, 3vw, 30px);
//             font-weight: 600;
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             transform: translateY(-50%) translateX(-50%);
//             width: 100%;
//             margin: 0 auto;
//             text-align: center;
//             z-index: 1;
//             padding: 0 clamp(10px, 2vw, 20px);
//             color: $wh1;

//             strong {
//                 font-size: clamp(18px, 4vw, 40px);
//             }
//         }
//     }

//     a:hover {
//         img {
//             transform: scale(1.1);
//         }
//     }

//     a{
//         img {
//             transition: all .3s;
//         }
//     }

//     // アプローチ
//     &--col2 {
//         >li {
//             width: calc(calc(100% - clamp(20px, 4vw, 40px))/2);
//             margin: 0 clamp(10px, 4vw, 40px) clamp(10px, 4vw, 30px) 0;

//             &:nth-child(2n) {
//                 margin-right: 0;
//             }

//             &:last-child {
//                 @include mq(sp) {
//                     margin-right: 0;
//                 }
//             }
//         }
//     }

//     // こんな方におすすめ
//     &--col3 {

//         >li{
//             width: calc(calc(100% - 50px)/3);
//             margin-right: 25px;

//             @include mq(sp) {
//                 width: calc(calc(100% - 10px)/2);
//                 margin: 0 10px 10px 0;
//             }

//             &:nth-child(3n) {
//                 margin-right: 0;
//                 @include mq(sp) {
//                     margin-right: 10PX;
//                 }
//             }

//             &:nth-child(2n) {
//                 @include mq(sp) {
//                     margin-right: 0;
//                 }
//             }
            
//             &:last-child {
//                 @include mq(sp) {
//                     margin-right: 0;
//                 }
//             }
//         }
//     }

//     // こんな方におすすめ(body_make-up)
//     &--col4 {
//         >li {
//             width: calc(calc(100% - clamp(15px, 4.5vw, 45px))/4);
//             margin: 0 clamp(5px, 1.5vw, 15px) clamp(5px, 1.5vw, 15px) 0;

//             @include mq(sp) {
//                 width: calc(calc(100% - 10px)/2);
//                 margin: 0 10px 10px 0;
//             }

//             &:nth-child(4n) {
//                 margin-right: 0;
//             }

//             &:nth-child(2n) {
//                 @include mq(sp) {
//                     margin-right: 0;
//                 }
//             }

//             &:last-child {
//                 @include mq(sp) {
//                     margin-right: 0;
//                 }
//             }
//         }
//     }

//     // 来院されるお客様のよくあるお悩み
//     &--col5{
//         >li {
//             width: calc(calc(100% - 40px)/5);
//             margin: 0 10px 10px 0;

//             @include mq(sp){
//                 width: calc(calc(100% - 20px)/3);
//             }

//             &:nth-child(3n){
//                 @include mq(tab){
//                     margin-right: 0;
//                 }
//             }

//             &:nth-child(5n) {
//                 margin-right: 0;
//             }

//             &:last-child {
//                 @include mq(sp) {
//                     margin-right: 0;
//                 }
//             }
//         }
//     }

//     // アプローチ
//     &--black {
//         .c-gray__list-item {
//             &::before {
//                 background: $bk16;
//                 mix-blend-mode: multiply;
//             }
//         }
//     }

//     // 来院されるお客様のよくあるお悩み
//     &--small {
//         .c-gray__list-item {
//             p {
//                 font-size: clamp(14px,3.5vw,20px);
//             }
//         }
//     }
// }

// /************************************************************
// *  共通ボックス のスタイルを定義
// *************************************************************/
.c-box01{
    padding:0 clamp(10px,2vw,20px);
    @include mq(sp) {
        padding: 0;
    }
}

// /************************************************************
// *  共通マップ のスタイルを定義
// *************************************************************/

.c-map01 {
    width: 1020px;
    max-width: 100%;
    height: clamp(300px,50vw,500px);
    max-height: 100%;
    margin: 0 auto;

    iframe {
        width: 100%;
        height: 100%;
    }
}