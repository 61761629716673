/************************************************************
*  追従ボタン
*************************************************************/
@use '../../global' as *;

.s-footer-pagetop {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10;

    &__link {
        display: block;

        &-btn{
            background: url('../images/common/foot_btn.jpg');
            background-size: 100%;
            width: clamp(50px,8vw,80px);
            height: clamp(50px,8vw,80px);
            display: block;
            transition: .35s;
        }

        &:hover &-btn{
            background: url('../images/common/foot_btn_hover.jpg');
            background-size: 100%;
        }
    }
}