/************************************************************
*  タイトル のスタイルを定義
*************************************************************/
@use '../../global' as *;

// .c-ttl01 {
//     font-size: clamp(24px, 5vw, 50px);
//     font-weight: 600;
//     margin-bottom: clamp(30px, 8vw, 80px);
//     text-align: center;
//     // line-height: $line-height-15;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     overflow: hidden;

//     @include mq(tab) {
//         margin-bottom: clamp(30px, 10vw, 60px);
//     }

//     &__main {
//         display: inline-block;
//         letter-spacing: 5px;

//         @include mq(tab) {
//             letter-spacing: 3px;
//         }

//         @include mq(sp) {
//             letter-spacing: 2px;
//         }
//     }

//     &__sub {
//         font-size: clamp(18px, 3.5vw, 30px);
//         letter-spacing: 8px;

//         @include mq(sp) {
//             letter-spacing: 5px;
//         }
//     }

//     &::after {
//         content: '';
//         background: linear-gradient(90deg, rgba($bk1, 0%) 0%, $or2 50%, rgba($bk1, 0%) 100%);
//         display: block;
//         height: 1px;
//         width: 800px;
//         margin: clamp(10px, 3vw, 30px) auto 0;

//         @include mq(sp) {
//             width: 100%;
//         }
//     }

//     &--black {
//         color: $bk1;
//     }

//     // &--white {
//     //     &::after {
//     //         background: linear-gradient(90deg, rgba($bk1, 0%) 0%, $wh1 50%, rgba($bk1, 0%) 100%);
//     //     }
//     // }

//     &--mv {
//         font-size: clamp(30px, 7vw, 66px);
//         font-weight: 400;

//         &::after {
//             content: '';
//             background: linear-gradient(90deg, rgba($bk1, 0%) 0%, $wh1 50%, rgba($bk1, 0%) 100%);
//             display: block;
//             width: 745px;
//             margin: 10px auto 0;
//         }
//     }

//     &--program {
//         color: $bk1;
//         line-height: $line-height-13;
//         margin-bottom: clamp(30px, 6vw, 60px);

//         @include mq(sp) {
//             text-shadow: 0 0 4px rgba($wh1, 50%), 0 0 4px rgba($wh1, 50%), 0 0 4px rgba($wh1, 50%), 0 0 4px rgba($wh1, 50%), 0 0 4px rgba($wh1, 50%), 0 0 4px rgba($wh1, 50%), 0 0 4px rgba($wh1, 50%), 0 0 4px rgba($wh1, 50%), 0 0 4px rgba($wh1, 50%), 0 0 4px rgba($wh1, 50%), 0 0 4px rgba($wh1, 50%), 0 0 4px rgba($wh1, 50%), 0 0 4px rgba($wh1, 50%), 0 0 4px rgba($wh1, 50%), 0 0 4px rgba($wh1, 50%), 0 0 4px rgba($wh1, 50%);
//         }

//         &::after {
//             margin-top: 10px;
//         }
//     }

//     &--small {
//         margin-bottom: clamp(30px, 6vw, 60px);

//         &::after {
//             margin: clamp(10px, 3vw, 25px) auto 0;
//         }
//     }

//     &--mb120 {
//         margin-bottom: clamp(30px, 12vw, 120px);
//     }
// }

// .c-ttl02 {
//     font-size: clamp(20px, 4vw, 36px);
//     font-weight: 600;
//     position: relative;
//     margin-bottom: clamp(20px, 5vw, 50px);
//     // line-height: $line-height-15;
// }

.c-ttl03 {
    color: $wh1;
    font-size: clamp(22px, 4vw, 40px);
    font-weight: 600;
    text-align: center;
    background: linear-gradient(90deg, rgba($br15, 0%) 0%, $br15 25%, $br15 75%, rgba($br15, 0%) 100%);
    padding: clamp(7px, 2vw, 15px) 35px;
    margin-bottom: clamp(20px, 4vw, 40px);
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq(sp) {
        margin-right: -20px;
        margin-left: -20px;
    }

    &--mb60 {
        margin-bottom: clamp(30px, 6vw, 60px);
    }
}

.c-ttl04 {
    font-size: clamp(18px, 3.5vw, 30px);
    font-weight: 600;
    background: $bk1;
    padding: 4px clamp(10px, 2vw, 20px);
    margin-bottom: 20px;
    min-height: clamp(40px, 6vw, 60px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.c-ttl05 {
    font-size: clamp(20px, 4vw, 36px);
    font-weight: 600;
    border-bottom: 1px solid $wh1;
    padding-bottom: 5px;
    margin-bottom: 25px;
}

.c-ttl06 {
    font-size: clamp(18px, 3.5vw, 30px);
    min-height: clamp(50px, 11vw, 110px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: $br1;
}

.c-ttl07 {
    font-size: clamp(18px, 3.5vw, 26px);
    font-weight: 600;
    margin-bottom: clamp(20px, 3vw, 30px);
    position: relative;
    color: $wh1;
    padding-left: clamp(10px, 2vw, 20px);
    border-left: clamp(5px, 1vw, 10px) solid $br1;

    &--lh10 {
        font-size: clamp(18px, 4vw, 36px);
        line-height: $line-height-10;
        margin-bottom: clamp(20px, 4vw, 40px);
    }
}

.c-ttl08 {
    background: $pi1;
    letter-spacing: 3px;
    padding: 5px 0 5px clamp(10px, 2vw, 20px);
    min-height: clamp(30px, 7vw, 70px);
    display: flex;
    align-items: center;
    font-size: clamp(18px, 3vw, 30px);
    font-weight: 600;
    color: $wh1;
    margin-bottom: clamp(20px,4vw,40px);

    &__main {
        position: relative;
        padding-left: clamp(12px, 2.5vw, 25px);

        &::before {
            content: '';
            background: $wh1;
            width: clamp(5px, 1vw, 10px);
            display: block;
            position: absolute;
            height: clamp(15px, 3vw, 30px);
            top: 11px;
            left: 0;

            @include mq(sp) {
                top: 4.5px;
            }
        }
    }

    &--bdnone {
        min-height: clamp(35px, 7vw, 70px);
        padding-left: clamp(10px, 3vw, 30px);

        &::before {
            display: none;
        }
    }
}

.c-ttl09 {
    font-size: clamp(18px, 3.5vw, 28px);
    font-weight: 600;
    border-bottom: 1px solid $br15;
    position: relative;
    margin-bottom: clamp(20px, 3vw, 30px);
    line-height: $line-height-15;
    padding-bottom: clamp(5px, 1vw, 10px);

    &::before {
        content: "";
        border-top: 1px solid $pi1;
        position: absolute;
        bottom: -1px;
        width: 230px;

        @include mq(tab) {
            width: 41%;
        }
    }

    &--bd {
        font-size: clamp(18px, 4vw, 36px);
        line-height: $line-height-14;
        padding-bottom: 5px;
        margin-bottom:clamp(30px,6vw,60px);

        .c-ttl09__main {
            position: relative;
            display: inline-block;
            padding-left: 15px;

            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                width: 5px;
                height: 32%;
            }

            &::before {
                background: $pi1;
                top: 18%;
            }

            &::after {
                background: $br15;
                bottom: 18%;
            }
        }
    }
}

.c-ttl10 {
    font-size: clamp(16px, 3.5vw, 20px);
    font-weight: 600;
    line-height: $line-height-14;
    margin-bottom: clamp(20px, 3vw, 30px);
    border-radius: 5px;
    color: $pi1;
    position: relative;
    padding: 4px 0 4px 20px;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 5px;
        height: 50%;
    }

    &::before {
        background: $pi1;
        top: 0;
    }

    &::after {
        background: $br15;
        bottom: 0;
    }

    &--black {
        color: $bk1;
    }
}

// .c-ttl11 {
//     font-size: clamp(16px, 3.5vw, 30px);
//     font-weight: 600;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     min-height: clamp(50px, 10vw, 100px);
//     background: $br1;
//     transition: all .35s;

//     &__main {
//         display: inline-block;
//         position: relative;
//         padding-right: clamp(20px, 4vw, 40px);

//         .c-btn__arrow {
//             width: 8px;
//             height: 8px;
//             right: 0;
//             transform: translateX(-25%) rotate(135deg);

//             &::before {
//                 height: 2px;
//             }

//             &::after {
//                 width: 2px;
//             }
//         }
//     }
// }