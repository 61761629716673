/************************************************************
*  テーブル のスタイルを定義
*************************************************************/
@use '../../global' as *;

// .c-table01 {

//     tr {
//         display: flex;
//         justify-content: space-between;
//         align-items: flex-start;
//         border-bottom: 1px solid $pi2;
//         min-height: clamp(40px, 7vw, 70px);
//         padding: 19px 0 11px 20px;

//         @include mq(sp) {
//             display: block;
//             padding: 10px;
//         }

//         &:first-child {
//             @include mq(sp) {
//                 padding-top: 0;
//             }
//         }
//     }

//     th {
//         color: $bk1;
//         font-size: clamp(14px, 3.5vw, 24px);
//         padding-right: clamp(20px,6vw,60px);

//         @include mq(sp) {
//             padding-right: 0;
//         }
//         // display: inline-flex;
//         // flex-wrap: wrap;
//         span {
//             font-size: clamp(13px, 3.5vw, 18px);
//         }

//         small {
//             font-size: clamp(10px,2.8vw,14px);
//         }
//     }

//     td {
//         font-size: clamp(13px, 3.5vw, 18px);
//         // font-weight: 400;
//         color: $wh1;
//         background: $pi1;
//         padding: 5px clamp(5px,1vw,10px);
//         white-space: nowrap;

//         @include mq(sp) {
//             width: max-content;
//         }
//     }

//     *+td {
//         @include mq(sp) {
//             margin-top: 5px;
//         }
//     }

//     th,
//     td {
//         line-height: $line-height-13;

//         @include mq(sp) {
//             display: block;
//         }
//     }
// }

// 法人概要
$table02_th: 280px;
$table02_padding: clamp(10px,2.7vw,27px);

.c-table02 {

    th,td{
        font-size: clamp(13px,3.5vw,20px);
        vertical-align: middle;
        @include mq(sp){
            display: block;
            padding: 5px 10px;
            width: 100%;
            border: none;
        }
    }

    th{
        text-align: center;
        width: $table02_th;
        padding: $table02_padding 10px;
        background: $br9;
        border: 1px solid $br9;
        border-bottom-color: $bl1;
    }

    td{
        width: calc(100% - $table02_th);
        padding: $table02_padding clamp(20px,5vw,50px);
        background: $wh1;
        color: $bk1;
        border: 1px solid $bl1;
        border-left: none;
    }
}

// 特定商法取引
// .c-table03 {

//     dt{
//         font-size: clamp(16px, 3.5vw, 24px);
//         font-weight: 600;
//         margin-bottom: clamp(10px,2vw,20px);
//         padding-left: clamp(10px,2vw,15px);
//         position: relative;
//         color: $bk1;
//         border-left: clamp(5px,1vw,10px) solid $pi1;
//     }

//     dd{
//         font-size: clamp(14px, 3.5vw, 20px);
//         margin-bottom: clamp(30px,5vw,50px);
//         line-height: $line-height-20;
//     }
// }

// クリニックアンカーリンク
// $table04_th: 160px;
// .c-table04 {
//     th,
//     td {
//         max-width: 100%;
//         // vertical-align: middle;
//         @include mq(sp) {
//             display: block;
//             width: 100%;
//         }
//     }

//     th{
//         width: $table04_th;
//         padding-bottom: clamp(10px,2vw,20px);
        
//     }

//     td{
//         width: calc(100% - $table04_th);
//         @include mq(sp) {
//             padding-bottom: 10px;
//         }
//     }
// }

// コラム用
$table05_th_sp: 150px;
.c-table05 {
    $c-table05: &;

    &__head{
        background: $pi1;
        color: $wh1;
        font-size: clamp(13px,3.5vw,16px);
        padding: 10px 5px;
        text-align: center;

        &:not(:last-child) {
            border-right: 1px solid lighten($pi1,20%);
        }
    }
    // tbody内

    &__ttl,&__cont {
        font-size: clamp(13px,3.5vw,16px);
        padding: 10px;
    }

    &__ttl {
        background: $pi1;
        color: $wh1;
        border: 1px solid lighten($pi1,20%);
        border-left: none;
    }
    &__cont {
        border: 1px solid lighten($bk1,80%);
        border-left: none;
    }

    // ヘッドの数
    &--col2 {
        #{$c-table05}__head {
            width: 50%;
        }
    }
    &--col3 {
        #{$c-table05}__head {
            width: 33.33%;
            @include mq(sp) {
                width: $table05_th_sp;
            }
        }
    }
    &--col4 {
        #{$c-table05}__head {
            width: 25%;
            @include mq(sp) {
                width: $table05_th_sp;
            }
        }
    }
    &--col5 {
        #{$c-table05}__head {
            width: 20%;
            @include mq(sp) {
                width: $table05_th_sp;
            }
        }
    }
}

.c-scroll {
    @include mq(tab) {
        position: sticky;
        left: 0;
        z-index: 2;
    }
}