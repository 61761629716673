@use '../global/' as *;

.l-flex {
  display: flex;

  &--jcsb {
    justify-content: space-between;
  }
  &--jcc {
    justify-content: center;
  }
  &--aic {
    align-items:center;
  }
  &--ais {
    align-items: flex-start;
  }
  &--fww {
    flex-wrap:wrap;
  }
  &-tabBlock {
    @include mq(tab) {
      display: block;
    }
  }
  &-spBlock {
    @include mq() {
      display: block;
    }
  }
}

.l-inner1100 {
  width: 1140px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  box-sizing: border-box;
}

.l-wrapper {
  // padding-top:80px;
  // @include mq(sp){
  //   padding-top:50px;
  // }
}

.l-wrapper.l-wrapper--column{
  padding-top:80px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 80px;
    background: $bk1;
    z-index: 1;
    @include mq(sp){
      height: 50px;
    }
  }
  @include mq(sp){
    padding-top:50px;
  }
}

/*ヘッダー*/

.l-header {
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 80px;
  // z-index: 10;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // padding-left:20px;
  // background: rgba($bk1,.85);
  // @include mq(sp){
  //   height: 50px;
  // }
}

// opinfo(施術ページのみの処理)
.l-header.is-active{
  box-shadow: unset;
}

/* フッター */

.l-main {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}