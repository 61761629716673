/************************************************************
*  Utility
*  汎用的なスタイルを定義
*************************************************************/
@use '../../global' as *;

/* 下線
--------------------------------------------------------------------------------------- */
.u-external_link {
    transition: .3s ease;
    text-decoration: underline;
}

.u-external_link:hover {
    opacity: .8;
    text-decoration: none;
}

/* PC/SP 非表示
--------------------------------------------------------------------------------------- */

.u-sp_none {
    display: block !important;

    @include mq() {
        display: none !important;
    }
}

.u-pctab_none {
    display: none !important;

    // 1023pxからblock
    @include mq(tab) {
        display: block !important;
    }
}

.u-tab_none {
    display: block !important;

    // 1023px～768pxまでnone
    @include mq(tab) {
        display: none !important;
    }

    @include mq(sp) {
        display: block !important;
    }
}

.u-sptab_none {
    display: block !important;

    // 1023pxからnone
    @include mq(tab) {
        display: none !important;
    }
}

.u-pc_none {
    display: none !important;

    @include mq() {
        display: block !important;
    }
}

/* ドットリスト（・）
--------------------------------------------------------------------------------------- */


.u-list_dot {
    li {
        position: relative;
        padding-left: 1.5rem;
        text-align: left;
        font-size: clamp(13px,3.5vw,15px);
        & + li {
            margin-top: 10px;
        }
        &::before {
            content: "・";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &--bd {
        li {
            a {
                transition: .3s ease;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}


/* 注釈リスト（※）
--------------------------------------------------------------------------------------- */

.u-list_notes {
    // line-height: $line-height-15;
    *+& {
        margin-top: .8em;
    }

    &--small {
        & li {
            font-size: clamp(10px, 2.5vw, 15px);
        }
    }
}

.u-list_notes li,
.u-notes {
    position: relative;
    list-style: none;
    padding: 0 0 0 1.2em;
    margin-bottom: 0;
    background-image: none;
    /* TODO タブレット最適化後に削除*/
    font-size: clamp(13px, 3.5vw, 15px);
    // font-weight: 400;
    display: inline-block;

    &::before {
        content: "※";
        position: absolute;
        left: 0;
        display: inline-block;
    }
}

.u-list_notes li+li,
.u-notes + .u-notes{
    margin-top: 3px;
}

.u-notes--big{
    font-size: clamp(13px, 3.5vw, 20px);
}

.u-notes--small {
    font-size: clamp(10px, 2.5vw, 15px);
}

.u-list_notes--block {
    li {
        display: block;
    }
}

.u-notes--block {
    display: block;
}

/* アスタリスクリスト（*）
--------------------------------------------------------------------------------------- */

.u-list_asterisk li,
.u-asterisk {
    display: block;
    position: relative;
    padding-left: 1rem;
    text-align: left;
    font-size: clamp(13px,3.5vw,15px);
}

.u-list_asterisk li::before,
.u-asterisk::before {
    content: "*";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.u-list_asterisk li.dot_none::before {
    display: none;
}

/* テキストインデント
--------------------------------------------------------------------------------------- */

.u-txt-indent {
    text-indent: -1em;
    padding-left: 1em;
}

/* チェックリスト
--------------------------------------------------------------------------------------- */

// コラムで使用
.u-list_check li {
    position: relative;
    list-style: none;
    padding: 0 0 0 1.5em;
    margin-bottom: 0;
    background-image: none;
    /* TODO タブレット最適化後に削除*/
    font-size: clamp(13px, 3.5vw, 15px);
}

.u-list_check li+li {
    margin-top: 10px;
}

.u-list_check li::before {
    content: "";
    position: absolute;
    top: 1px;
    left: 0;
    display: inline-block;
    background: url(../images/column/check_icon.png) no-repeat;
    background-size: 100%;
    width: 17px;
    height: 17px;
}

/* ひし形リスト
--------------------------------------------------------------------------------------- */

// .u-list_rhombus {
//     >li {
//         position: relative;
//         padding-left: 40px;
//         font-size: 26px;
//         color: $bl6;
//         font-weight: 600;
//         line-height: $line-height-15;

//         @include mq(sp) {
//             font-size: 14px;
//             padding-left: 20px;
//         }

//         &::before {
//             content: '';
//             width: 22px;
//             height: 22px;
//             background: ;
//             display: block;
//             transform: rotate(45deg);
//             position: absolute;
//             top: 8px;
//             left: 0;

//             @include mq(sp) {
//                 width: 10px;
//                 height: 10px;
//                 top: 6px;
//             }
//         }
//     }

//     >li+li {
//         margin-top: 20px;
//         @include mq(sp) {
//             margin-top: 5px;
//         }
//     }
// }

/* 丸リスト
--------------------------------------------------------------------------------------- */

.u-list_circle {
    margin-top: 5px;
    margin-bottom: 5px;

    &>li {
        position: relative;
        padding-left: 1em;
        line-height: $line-height-15;

        &::before {
            content: '';
            border-radius: 50%;
            display: inline-block;
            height: 10px;
            left: 2px;
            position: absolute;
            top: 7px;
            width: 10px;
            background-color: $wh1;
        }
    }

    &>li+li {
        margin-top: 20px;
    }
}


/* text-align
--------------------------------------------------------------------------------------- */

.u-align {
    &_c {
        text-align: center;
    }

    &_l {
        text-align: left;
    }

    &_r {
        text-align: right;
    }
}

@include mq(sp) {
    .u-spalign {
        &_c {
            text-align: center;
        }
    
        &_l {
            text-align: left;
        }
    
        &_r {
            text-align: right;
        }
    }
}

/* 揃え
--------------------------------------------------------------------------------------- */

.u-margin{

    &_l{
        margin: 0 auto 0 0;
    }

    &_c {
        margin: 0 auto;
    }

    &_r{
        margin: 0 0 0 auto;
    }
}



/* 文字色
--------------------------------------------------------------------------------------- */

.u-color-pink {
    color: $pi1;
}

/* 背景色
--------------------------------------------------------------------------------------- */

.u-bg__gray{
    background-color: $bk17;
}

/* ボーダー
--------------------------------------------------------------------------------------- */

.u-border_current {
    border-bottom: 1px solid currentColor;
}

.u-border_white {
    border-bottom: 1px solid $wh1;
}

/* フォント
--------------------------------------------------------------------------------------- */
.u-ff-yugoshic {
    font-family: $ff-yugoshic  !important;
}

.u-ff-yugoshic-bold {
    font-family: $ff-yugoshic-bold  !important;
}

.u-ff-yumincho {
    font-family: $ff-yumincho  !important;
}

// font-sizeを定義
@for $i from 10 through 20 {
    .u-fsize_#{$i} {
        font-size: $i+px;
    }
}

// margin: top,bottomを定義
$margin: 0;

@while $margin < 301 {
    .u-mt#{$margin} {
        margin-top: $margin + px !important;
    }

    .u-mb#{$margin} {
        margin-bottom: $margin + px !important;
    }

    .u-mr#{$margin} {
        margin-right: $margin + px !important;
    }

    .u-ml#{$margin} {
        margin-left: $margin + px !important;
    }

    $margin: $margin + 5;
}

// padding: top,bottomを定義
$padding: 0;

@while $padding < 101 {
    .u-pt#{$padding} {
        padding-top: $padding + px !important;
    }

    .u-pb#{$padding} {
        padding-bottom: $padding + px !important;
    }

    $padding: $padding + 5;
}

$column_gap: 0;

@while $column_gap < 101 {
    .u-cg#{$column_gap} {
        column-gap: $column_gap + px !important;
    }

    .u-cg#{$column_gap} {
        column-gap: $column_gap + px !important;
    }

    $column_gap: $column_gap + 5;
}


@include mq() {

    // font-sizeを定義
    @for $i from 10 through 21 {
        .u-spfsize_#{$i} {
            font-size: $i+px;
        }
    }

    // margin: top,bottomを定義
    $margin: 0;

    @while $margin < 151 {
        .u-spmt#{$margin} {
            margin-top: $margin + px !important;
        }

        .u-spmb#{$margin} {
            margin-bottom: $margin + px !important;
        }

        .u-mr#{$margin} {
            margin-right: $margin + px !important;
        }
    
        .u-ml#{$margin} {
            margin-left: $margin + px !important;
        }

        $margin: $margin + 5;
    }

    // padding: top,bottomを定義
    $padding: 0;

    @while $padding < 151 {
        .u-sppt#{$padding} {
            padding-top: $padding + px !important;
        }

        .u-sppb#{$padding} {
            padding-bottom: $padding + px !important;
        }

        $padding: $padding + 5;
    }
}