/************************************************************
*  headerのスタイルを定義
*************************************************************/
@use '../../global' as *;

.s-head {
    border-top: 10px solid $pi1;
    padding-top: 40px;

    // トップページのみの処理
    &--top{
        padding-top: 70px;
        .s-logo {
            width: clamp(200px,64vw,640px);
        }
    }
}

.s-logo {
    text-align: center;
    margin: 0 auto;

    @include mq(sp) {
        width: clamp(200px,30vw,300px);
        max-width: 100%;
    }
}
